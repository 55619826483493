<template>
	<div class="tabulation">
		<el-card class="chart-card">
			<div style="margin-top: 20px;">
				<el-form ref="ruleFormRef" :model="form" :rules="rules" class="demo-ruleForm" :size="formSize"
					status-icon>
					<!-- 请选择工会 -->
					<el-form-item label="选择工会" prop="choose">
						<div class="aaaa">
							<el-input v-model="form.choose" readonly placeholder="请选择所属工会"
								@click="visible = false"></el-input>
						</div>
					</el-form-item>
					<!-- 商户类型 -->
					<el-form-item label="商户类型" prop="genre">
						<el-select v-model="form.genre" placeholder="请选择商户类型">
							<el-option label="自有微信商户号" value="1"
								style="width:100%; text-align-last: center;"></el-option>
							<el-option label="服务商子商户模式" value="2"
								style="width:100%; text-align-last: center;"></el-option>
						</el-select>
					</el-form-item>
					<!-- 微信商户号 -->
					<el-form-item label="微信商户号" prop="name">
						<div class="aaaa">
							<el-input v-model="form.name" placeholder="请填写微信商户号"></el-input>
						</div>
					</el-form-item>
					<!-- 商户支付KEY -->
					<el-form-item label="商户支付KEY" prop="payment">
						<div class="aaaa">
							<el-input v-model="form.payment" placeholder="请填写商户支付KEY"></el-input>
						</div>
					</el-form-item>
					<!-- 备注 -->
					<el-form-item label="备注" prop="remark">
						<div class="aaaa">
							<el-input type="textarea" v-model="form.remark" placeholder="请填写备注"></el-input>
						</div>
					</el-form-item>
					<!-- 提交按钮 -->
					<div class="submit">
						<el-form-item>
							<el-button type="primary" :loading="loa" @click="onSubmit">提交</el-button>
						</el-form-item>
					</div>
					<div>
						<s3-layer v-model="visible" title="所属组织" :shadeClose="true" :area="['500px', '80vh']"
							@yes="yes">
							<el-tree :data="texture" :props="defaultProps" accordion @node-click="dendrogram" />
						</s3-layer>
					</div>
				</el-form>
			</div>
		</el-card>
	</div>
</template>

<script>
	import {
		ref,
		reactive,
		onMounted,
		onActivated
	} from "vue";
	import {
		ZClist,
		HFparametersdit,
		HFparameterssave
	} from '../../utils/api'
	import {
		ElMessage
	} from "element-plus";
	import qs from 'qs'
	import {
		useRouter,
		useRoute
	} from "vue-router";
	import {
		useStore
	} from "vuex";
	export default {
		name: "HFargudit",
		setup() {
			const route = useRoute();
			const loading = ref(true)
			// 获取工会code
			let hqcode = ref('')
			// 获取编辑信息
			const HFlist = (() => {
				let data = {
					paymentParametersId: sessionStorage.getItem('HFparameter')
				}
				HFparametersdit(qs.stringify(data)).then((res) => {
					// if(res.code)
					if (res.data.code == 0) {
						loading.value = false
						messge()
						// 工会
						hqcode.value = res.data.data.paymentParametersInfo.unionCode
						gaincode.value = res.data.data.paymentParametersInfo.unionCode
						// 微信商户号 
						form.name = res.data.data.paymentParametersInfo.mchId
						// 商户key 
						form.payment = res.data.data.paymentParametersInfo.mchKey
						// 备注
						form.remark = res.data.data.paymentParametersInfo.remarks
						// 商户类型
						if (res.data.data.paymentParametersInfo.payType == 1) {
							form.genre = '自有微信商户号'
						} else if (res.data.data.paymentParametersInfo.payType == 2) {
							form.genre = '服务商子商户模式'
						}
					}
					console.log(res)
				})
			})
			let translate = ref(2)
			onActivated(() => {
				translate.value = route.params.UserId
				if (translate.value == 1) {
					loading.value = true
					HFlist()
				}
			})
			onMounted(() => {
				HFlist()
			})
			// 获取工会列表信息
			const messge = () => {
				ZClist().then((res) => {
					console.log(res)
					texture.value = res.data.data.manageUnionList
					const arr3 = texture.value.filter(function(num) {
						return num.unionCode == hqcode.value
					})
					if (arr3.length == 0) {
						texture.value.forEach((item) => {
							const arr4 = item.unionDtoList.filter(function(num) {
								return num.unionCode == hqcode.value
							})
							if (arr4.length == 0) {
								item.unionDtoList.forEach((items) => {
									const arr5 = items.unionDtoList.filter(function(nums) {
										return nums.unionCode == hqcode.value
									})
									if (arr5.length == 0) {
										items.unionDtoList.forEach((items1) => {
											const arr6 = items1.unionDtoList.filter(
												function(numss) {
													return numss.unionCode ==
														hqcode.value
												})
											if (arr6.length == 0) {
												items1.unionDtoList.forEach((
												items2) => {
													const arr7 = items2
														.unionDtoList.filter(
															function(numss1) {
																return numss1
																	.unionCode ==
																	hqcode
																	.value
															})
													if (arr7.length == 0) {
														items2.unionDtoList
															.forEach((
																items3) => {
																	const
																		arr8 =
																		items3
																		.unionDtoList
																		.filter(
																			function(
																				numss2
																				) {
																				return numss2
																					.unionCode ==
																					hqcode
																					.value
																			})
																	if (arr8
																		.length ==
																		0) {
																		items3
																			.unionDtoList
																			.forEach(
																				(
																					items4) => {
																					const
																						arr9 =
																						items4
																						.unionDtoList
																						.filter(
																							function(
																								numss3
																								) {
																								return numss3
																									.unionCode ==
																									hqcode
																									.value
																							}
																							)
																					arr9.forEach(
																						(
																							item) => {
																							form.choose =
																								arr3
																								.unionName =
																								item
																								.unionName
																						}
																						)
																				}
																				)
																	}
																	arr8.forEach(
																		(
																			item) => {
																			form.choose =
																				arr3
																				.unionName =
																				item
																				.unionName
																		})
																})
													}
													arr7.forEach((item) => {
														form.choose =
															arr3
															.unionName =
															item
															.unionName
													})
												})
											}
											arr6.forEach((item) => {
												form.choose = arr3.unionName =
													item.unionName
											})
										})
									}
									arr5.forEach((item) => {
										form.choose = arr3.unionName = item.unionName
									})
								})
							} else {
								arr4.forEach((item) => {
									form.choose = arr3.unionName = item.unionName
								})
							}
						})
					} else {
						arr3.forEach((item) => {
							form.choose = arr3.unionName = item.unionName
						})
					}
				})
			}
			// 所属工会选择
			const yes = () => {
				form.choose = options.value
				visible.value = false;
			};
			// 获取工会code
			let gaincode = ref('')
			// 树形选择
			let options = ref('')
			let dendrogram = (node) => {
				options.value = node.unionName
				form.choose = options.value
				gaincode.value = node.unionCode
			}
			const defaultProps = {
				children: 'unionDtoList',
				label: 'unionName',
			}
			let texture = ref([])
			const visible = ref(false);
			// 表单内容
			const ruleFormRef = ref(null)
			const form = reactive({
				name: "",
				genre: "",
				choose: "",
				payment: "",
				remark: ""
			})
			// 表单提交
			// 关闭当前页
			const store = useStore();
			const current = ref('')
			const expression = ref([])
			const Indexs = ref(null)


			const router = useRouter();
			const loa = ref(false)
			let SHclass = ref(null)
			const onSubmit = () => {
				// loa.value=true
				// 商户类型
				// 表单校验
				ruleFormRef.value.validate((valid) => {
					console.log(valid)
					if (form.genre == '自有微信商户号') {
						SHclass.value = 1
					} else if (form.genre == '服务商子商户模式') {
						SHclass.value = 2
					} else if (form.genre == 1) {
						SHclass.value = 1
					} else if (form.genre == 2) {
						SHclass.value = 2
					}
					if (valid == true) {
						// console.log('工会',gaincode.value)
						// console.log('商户类型',SHclass.value)
						// console.log('微信商户号',form.name)
						// console.log('商户支付KEY',form.payment)
						// console.log('备注',form.remark)
						// console.log('缴费参数id',sessionStorage.getItem('HFparameter'))
						let data = {
							"paymentParametersId": sessionStorage.getItem('HFparameter'),
							"payType": SHclass.value,
							"mchId": form.name,
							"mchKey": form.payment,
							"remarks": form.remark
						}
						HFparameterssave(qs.parse(data)).then((res) => {
							console.log(res)
							if (res.data.code == 0) {
								ElMessage({
									message: "提交成功",
									type: "success",
									center: true,
								});
								setTimeout(() => {
									current.value = route.path
									expression.value = store.state.tagsList

									expression.value.forEach((item, index) => {
										if (current.value == item.path) {
											Indexs.value = index
										}
									})
									store.state.tagsList.splice(Indexs.value, 1)
									router.push({
										name: 'HFargu',
										params: {
											userId: 1
										}
									});
									loa.value = false
								}, 500)
							}
						})
					} else if (valid == false) {
						ElMessage({
							message: "提交失败",
							type: "error",
							center: true,
						});
						loa.value = false
					}
				})
			}
			// 验证表单提示
			const rules = {
				name: [{
					required: true,
					message: "请填写微信商户号",
					trigger: "blur"
				}, ],
				genre: [{
					required: true,
					message: "请选择商户类型",
					trigger: "blur"
				}, ],
				choose: [{
					required: true,
					message: "请选择所属工会",
					trigger: "change"
				}, ],
				payment: [{
					required: true,
					message: "请填写商户支付KEY",
					trigger: "change"
				}, ],
				remark: [{
					required: true,
					message: "请填写备注",
					trigger: "change"
				}, ],
			}
			return {
				loading,
				SHclass,
				// 提交按钮
				loa,
				// 树形选择内容
				yes,
				visible,
				dendrogram,
				defaultProps,
				texture,
				// 表单内容
				ruleFormRef,
				form,
				// 表单提交按钮
				onSubmit,
				rules
			}
		},
	}
</script>>

<style lang='scss' scoped>
	::v-deep .chart-card::-webkit-scrollbar {
		display: none
	}

	::v-deep .chart-card {
		height: calc(100vh - 145px);
		overflow: auto;
	}

	::v-deep .subject {
		margin-left: 3px;
		font-size: var(--el-form-label-font-size);
		font-weight: bold;
		margin-bottom: 5px;
		color: var(--el-text-color-regular);

		.xingxing {
			color: var(--el-color-danger);
		}
	}

	// .w-e-text-container [data-slate-editor] p{
	//   margin-top: 22px;
	//   margin-bottom: 22px;
	// }
	::v-deep .el-button {
		width: 100px;
		height: 40px;
		font-size: 16px;
	}

	::v-deep .el-form-item__content {
		align-items: flex-end;
	}

	::v-deep .referrals {
		color: #cccccc;
		margin-left: 5px;
		font-weight: 500;
	}

	::v-deep .styB .el-upload--picture-card {
		width: 110px;
		height: 110px;
		line-height: 110px;
	}

	::v-deep .styC .el-upload--picture-card {
		display: none;
	}

	::v-deep .el-form-item {
		align-items: center;
	}

	::v-deep .aaaa {
		width: 50%;
	}

	::v-deep .el-card__body {
		padding: 0;
	}

	::v-deep .el-main {
		overflow: auto !important;
	}

	::v-deep .el-card.is-always-shadow {
		width: 95%;
		margin: auto;
		margin-top: 10px;
		padding-left: 20px;
		padding-right: 20px;
		padding-bottom: 20px;
	}

	::v-deep .tabulation {
		margin-top: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 10px;
		overflow: auto !important;
	}
</style>
<style scoped>
	::v-deep .upload-demo {
		display: flex;
		width: 100%;
	}

	::v-deep .upload-demo .el-upload-list {
		display: flex;
		width: 50%;
	}

	::v-deep .el-input {
		height: 44px !important;
	}

	::v-deep .el-form-item__label {
		width: 125px !important;
		font-weight: bold;
	}

	::v-deep .submit {
		margin-left: 125px;
	}
</style>